export default {
  header: {
    title: "RODFAIFA",
    vehiclesFound: "พบ {{count}} รายการ",
    quiz: "ค้นหารถที่ใช่"
  },
  filters: {
    search: "ค้นหา",
    priceRange: "ช่วงราคา",
    batteryRange: "ระยะทางวิ่ง",
    min: "ต่ำสุด",
    max: "สูงสุด",
    sortBy: "เรียงตาม",
    vehicleType: "ประเภทรถ",
    brand: "แบรนด์",
    charging: "ความเร็วในการชาร์จ",
    features: "ฟีเจอร์",
    specs: "ข้อมูลจำเพาะ"
  },
  sorting: {
    "price-asc": "ราคา: ต่ำไปสูง",
    "price-desc": "ราคา: สูงไปต่ำ",
    "range-desc": "ระยะทาง: สูงไปต่ำ",
    "acceleration-asc": "อัตราเร่ง: เร็วที่สุดก่อน"
  },
  comparison: {
    title: "เปรียบเทียบ {{brand1}} กับ {{brand2}} รถยนต์ไฟฟ้าในประเทศไทย (2024)",
    description: "เปรียบเทียบรถยนต์ไฟฟ้า {{brand1}} และ {{brand2}} ในประเทศไทย ราคา สเปค และฟีเจอร์ของทุกรุ่น",
    specifications: "ข้อมูลจำเพาะ",
    availableModels: "รุ่นที่มีจำหน่าย",
    priceRange: "ช่วงราคา",
    invalidComparison: "การเปรียบเทียบไม่ถูกต้อง",
    brandNotFound: "ไม่พบแบรนด์",
    brandNotFoundDescription: "ไม่พบแบรนด์ที่คุณต้องการ กรุณาลองแบรนด์อื่น",
    compareModels: "เปรียบเทียบรุ่น",
    priceComparison: "เปรียบเทียบราคา",
    batteryCapacity: "ความจุแบตเตอรี่",
    range: "ระยะทางวิ่ง",
    acceleration: "อัตราเร่ง",
    charging: "ความเร็วในการชาร์จ",
    features: "ฟีเจอร์",
    specs: "ข้อมูลจำเพาะ"
  },
  vehicle: {
    compare: "เปรียบเทียบ",
    added: "เพิ่มแล้ว",
    specifications: "ข้อมูลจำเพาะ",
    range: "ระยะทาง",
    acceleration: "0-100 กม./ชม.",
    battery: "แบตเตอรี่",
    topSpeed: "ความเร็วสูงสุด",
    year: "ปี",
    price: "ราคา",
    power: "กำลัง",
    torque: "แรงบิด",
    drive: "ระบบขับเคลื่อน",
    seating: "ที่นั่ง",
    cargo: "พื้นที่เก็บสัมภาระ"
  },
  types: {
    SUV: "เอสยูวี",
    Sedan: "รถเก๋ง",
    Hatchback: "แฮทช์แบ็ก",
    Truck: "กระบะ",
    Sports: "รถสปอร์ต",
    Van: "รถตู้"
  },
  contact: {
    title: "ติดต่อเรา",
    name: "ชื่อ",
    email: "อีเมล",
    message: "ข้อความ",
    send: "ส่งข้อความ",
    sending: "กำลังส่ง...",
    success: "ส่งข้อความสำเร็จ!",
    error: "ไม่สามารถส่งข้อความได้ กรุณาลองใหม่อีกครั้ง"
  },
  experience: {
    title: "ประสบการณ์ผู้ใช้",
    addExperience: "แชร์ประสบการณ์",
    searchExperiences: "ค้นหาประสบการณ์...",
    filterByMake: "กรองตามยี่ห้อ",
    resultsFound: "พบ {{count}} รายการ",
    shareYourThoughts: "แชร์ความคิดเห็นของคุณ",
    selectVehicle: "เลือกรถยนต์",
    positive: "ประสบการณ์ที่ดี",
    negative: "ประสบการณ์ที่ไม่ดี",
    yourExperience: "ประสบการณ์ของคุณ",
    sharePlaceholder: "แชร์ประสบการณ์การใช้งานรถยนต์คันนี้...",
    yourName: "ชื่อของคุณ",
    submitting: "กำลังส่ง...",
    submit: "ส่งประสบการณ์",
    noResults: "ไม่พบประสบการณ์ที่ตรงกับเงื่อนไขการค้นหา",
    submitSuccess: "แชร์ประสบการณ์สำเร็จ!",
    submitError: "ไม่สามารถส่งประสบการณ์ได้ กรุณาลองใหม่อีกครั้ง",
    enterAdminKey: "กรุณาใส่รหัสผู้ดูแลระบบ",
    confirmDelete: "คุณแน่ใจหรือไม่ที่จะลบประสบการณ์���ี้?",
    deleteError: "ไม่สามารถลบประสบการณ์ได้",
    delete: "ลบประสบการณ์"
  },
  common: {
    backToHome: "กลับสู่หน้าแรก",
    loading: "กำลังโหลด...",
    error: "เกิดข้อผิดพลาด",
    notFound: "ไม่พบข้อมูล"
  },
  brandComparison: {
    pageTitle: "เปรียบเทียบ {{brand1}} vs {{brand2}} รถยนต์ไฟฟ้าในประเทศไทย (2024)",
    pageDescription: "เปรียบเทียบรถยนต์ไฟฟ้า {{brand1}} และ {{brand2}} ในประเทศไทย ราคา สเปค และฟีเจอร์ของทุกรุ่น",
    heading: "เปรียบเทียบ {{brand1}} กับ {{brand2}}",
    subheading: "เปรียบเทียบสเปค ราคา และฟีเจอร์ของทุกรุ่นที่มีจำหน่าย",
    detailedComparison: "รายละเอียดการเปรียบเทียบ",
    keyDifferences: "ความแตกต่างที่สำคัญ",
    priceComparison: "เปรียบเทียบราคา",
    rangeComparison: "เปรียบเทียบระยะทางวิ่ง",
    featuresComparison: "เปรียบเทียบฟีเจอร์",
    faqTitle: "คำถามที่พบบ่อย",
    relatedComparisons: "การเปรียบเทียบที่เกี่ยวข้อง",
    availableModels: "รุ่นที่มีจำหน่าย",
    priceRange: "ช่วงราคา",
    specifications: "ข้อมูลจำเพาะ",
    price: "ราคา",
    range: "ระยะทางวิ่ง",
    batteryCapacity: "ความจุแบตเตอรี่",
    acceleration: "อัตราเร่ง",
    compareModels: "เปรียบเทียบรุ่น",
    // Added the comparison text translations here
    priceComparisonText: "รถยนต์ {{brand1}} มีราคาเฉลี่ยที่ {{price1}} ในขณะที่ {{brand2}} มีราคาเฉลี่ยที่ {{price2}} ความแตกต่างของราคาระหว่างสองแบรนด์คือ {{difference}}",
    rangeComparisonText: "{{brand1}} มีระยะทางวิ่งสูงสุดที่ {{range1}} กม. เทียบกับ {{brand2}} ที่ {{range2}} กม. - ต่างกัน {{difference}} กม.",
    featuresComparisonText: "{{brand1}} มาพร้อมกับ {{specs1}} ในขณะที่ {{brand2}} มาพร้อมกับ {{specs2}}"
  },
  errors: {
    invalidComparison: "การเปรียบเทียบไม่ถูกต้อง",
    invalidComparisonDesc: "กรุณาเลือกแบรนด์ที่แตกต่างกันเพื่อเปรียบเทียบ",
    brandsNotFound: "ไม่พบแบรนด์",
    brandsNotFoundDesc: "ไม่พบแบรนด์ที่คุณต้องการ กรุณาลองแบรนด์อื่น",
  },
  // **New Quiz Translations**
  quiz: {
    title: "ค้นหารถไฟฟ้าที่เหมาะกับคุณ",
    consentText: "เราจะใช้คำตอบของคุณเพื่อแนะนำรถที่เหมาะสมที่สุด คุณยินยอมหรือไม่?",
    agree: "ยินยอม",
    questionBudget: "งบประมาณของคุณ",
    questionRange: "ระยะทางที่ต้องการ (กม.)",
    questionType: "ประเภทรถที่ต้องการ",
    questionSeating: "จำนวนที่นั่งที่ต้องการ",
    next: "ถัดไป",
    showResults: "ดูผลการแนะนำ",
    startOver: "เริ่มใหม่",
    recommendations: "รถที่แนะนำสำหรับคุณ",
    noRecommendations: "ไม่พบรถที่ตรงตามความต้องการ กรุณาปรับเกณฑ์��ารค้นหา",
    step: "ขั้นตอนที่ {{current}} จาก {{total}}",
    select: "กรุณาเลือก",
    seatingOptions: {
      '2': "2 ที่นั่ง",
      '4': "4 ที่นั่ง",
      '5': "5 ที่นั่ง",
      '7': "7 ที่นั่ง"
    }
  }
};
