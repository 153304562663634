export interface EVehicle {
  id: string;
  name: string;
  brand: string;
  type: 'SUV' | 'Sedan' | 'Hatchback' | 'Truck' | 'Sports' | 'Van' ;
  year: number;
  price: number;
  range: number;
  acceleration: number;
  batteryCapacity: number;
  topSpeed: number;
  image: string;
  gallery: string[];
  specs: {
    power: string;
    torque: string;
    drive: string;
    seating: number;
    cargo: string;
  };
}

export const vehicles: EVehicle[] = [
  {
    id: 'ioniq5',
    name: 'IONIQ 5 Premium', // Updated name
    brand: 'Hyundai',
    type: 'SUV',
    year: 2024,
    price: 1699000,  // Updated
    range: 384,      // Updated
    acceleration: 8.5, // Updated
    batteryCapacity: 58.0, // Updated
    topSpeed: 185,   // Updated
    image: 'https://buyonline-contents.hyundai.com/hmc-th-1/products/vehicle360/10012-10038/5/59db0f47-54ed-48b6-a8a4-b41142df212f.png',
    gallery: [
      'https://images.unsplash.com/photo-1669736567520-b3d841c95ba4?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1669736567530-f5ce0593d870?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '320 hp',
      torque: '446 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '27.2 cu ft'
    }
  },
  {
    id: 'ioniq5_exclusive',
    name: 'IONIQ 5 Exclusive',
    brand: 'Hyundai',
    type: 'SUV',
    year: 2024,
    price: 1829000,
    range: 481,
    acceleration: 7.4,
    batteryCapacity: 72.6,
    topSpeed: 185,
    image: 'https://buyonline-contents.hyundai.com/hmc-th-1/products/vehicle360/10012-10038/5/59db0f47-54ed-48b6-a8a4-b41142df212f.png',
    gallery: [
      'https://images.unsplash.com/photo-1669736567520-b3d841c95ba4?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1669736567530-f5ce0593d870?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '320 hp',
      torque: '446 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '27.2 cu ft'
    }
  },
  {
    id: 'ioniq5_first_edition',
    name: 'IONIQ 5 First Edition',
    brand: 'Hyundai',
    type: 'SUV',
    year: 2024,
    price: 2390000,
    range: 451,
    acceleration: 7.4,
    batteryCapacity: 72.6,
    topSpeed: 185,
    image: 'https://buyonline-contents.hyundai.com/hmc-th-1/products/vehicle360/10012-10038/5/59db0f47-54ed-48b6-a8a4-b41142df212f.png',
    gallery: [
      'https://images.unsplash.com/photo-1669736567520-b3d841c95ba4?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1669736567530-f5ce0593d870?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '320 hp',
      torque: '446 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '27.2 cu ft'
    }
  },
  {
    id: 'dolphin-standard',
    name: 'Dolphin Standard Range',
    brand: 'BYD',
    type: 'Hatchback',
    year: 2024,
    price: 699999,
    range: 410,
    acceleration: 12.3,
    batteryCapacity: 44.9,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fdolphin%2Ftech-spec%2Fstandard-range.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '70 kW',
      torque: '180 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '345 L'
    }
  },
  {
    id: 'dolphin-extended',
    name: 'Dolphin Extended Range',
    brand: 'BYD',
    type: 'Hatchback',
    year: 2024,
    price: 859999,
    range: 490,
    acceleration: 7.0,
    batteryCapacity: 60.48,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fdolphin%2Ftech-spec%2Fextended-range.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '290 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '345 L'
    }
  },
  {
    id: 'seal_dynamic',
    name: 'Seal Dynamic RWD',
    brand: 'BYD',
    type: 'Sedan',
    year: 2024,
    price: 1325000,
    range: 510,
    acceleration: 7.5,
    batteryCapacity: 61.44,  // Updated from 61.4
    topSpeed: 180,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fseal%2Fcolor%2Fseal-horizon-white.png&w=384&q=100',
    gallery: [],
    specs: {
      power: '204 hp',
      torque: '310 lb-ft',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '14.5 cu ft'
    }
  },
  {
    id: 'seal_premium',
    name: 'Seal Premium RWD',
    brand: 'BYD',
    type: 'Sedan',
    year: 2024,
    price: 1449000,
    range: 650,
    acceleration: 5.9,
    batteryCapacity: 82.56,
    topSpeed: 185,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fseal%2Fcolor%2Fseal-velocity-blue.png&w=384&q=100',
    gallery: [],
    specs: {
      power: '313 hp',
      torque: '360 lb-ft',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '14.5 cu ft'
    }
  },
  {
    id: 'seal_performance',
    name: 'Seal Performance AWD',  // Updated name to include AWD
    brand: 'BYD',
    type: 'Sedan',
    year: 2024,
    price: 1599000,
    range: 580,
    acceleration: 3.9,  // Updated from 3.8
    batteryCapacity: 82.56,  // Updated from 82.5
    topSpeed: 200,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fseal%2Fcolor%2Fseal-quantum-black.png&w=384&q=100',
    gallery: [],
    specs: {
      power: '530 hp',
      torque: '523 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '14.5 cu ft'
    }
  },
  {
    id: 'atto3_extended_new',
    name: 'Atto 3 Extended Range (New)',
    brand: 'BYD',
    type: 'SUV',
    year: 2024,
    price: 1049900,
    range: 480,
    acceleration: 7.3,
    batteryCapacity: 60,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-atto3%2Fcolor%2Fnew-atto3-quantum-black-1024x717.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '204 hp',
      torque: '310 lb-ft',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '28.4 cu ft'
    }
  },
  {
    id: 'atto3_dynamic_new',
    name: 'Atto 3 Dynamic (New)',
    brand: 'BYD',
    type: 'SUV',
    year: 2024,
    price: 899900,
    range: 410,
    acceleration: 7.9,
    batteryCapacity: 49.92,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-atto3%2Fcolor%2Fnew-atto3-graphite-grey-1024x717.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '440 L'
    }
  },
  {
    id: 'atto3_premium_new',
    name: 'Atto 3 Premium (New)',
    brand: 'BYD',
    type: 'SUV',
    year: 2024,
    price: 949900,
    range: 410,
    acceleration: 7.9,
    batteryCapacity: 49.92,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-atto3%2Fcolor%2Fnew-atto3-frost-white-1024x717.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '440 L'
    }
  },
  {
    id: 'dolphin-standard-new',
    name: 'Dolphin Standard Range (New)',
    brand: 'BYD',
    type: 'Hatchback',
    year: 2024,
    price: 569900,
    range: 410,
    acceleration: 12.3,
    batteryCapacity: 44.9,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-dolphin%2Ftech-spec%2Fstandard-range.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '70 kW',
      torque: '180 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '345 L'
    }
  },
  {
    id: 'dolphin-extended-new',
    name: 'Dolphin Extended Range (New)',
    brand: 'BYD',
    type: 'Hatchback',
    year: 2024,
    price: 709900,
    range: 490,
    acceleration: 7.0,
    batteryCapacity: 60.48,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-dolphin%2Ftech-spec%2Fextended-range.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '290 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '345 L'
    }
  },
  {
    id: 'atto3_standard',
    name: 'Atto 3 Standard Range',
    brand: 'BYD',
    type: 'SUV',
    year: 2023,  // Changed to 2023 to differentiate from new models
    price: 1099900,
    range: 410,
    acceleration: 7.3,
    batteryCapacity: 49.92,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-atto3%2Fcolor%2Fnew-atto3-frost-white-1024x717.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '440 L'
    }
  },
  {
    id: 'atto3_extended',
    name: 'Atto 3 Extended Range',
    brand: 'BYD',
    type: 'SUV',
    year: 2023,  // Changed to 2023 to differentiate from new models
    price: 1199900,
    range: 480,
    acceleration: 7.3,
    batteryCapacity: 60.48,
    topSpeed: 160,
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fnew-atto3%2Fcolor%2Fnew-atto3-quantum-black-1024x717.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '440 L'
    }
  },
  {
    id: 'm6_dynamic',
    name: 'M6 Dynamic',
    brand: 'BYD',
    type: 'SUV',
    year: 2024,
    price: 829900,
    range: 420,
    acceleration: 0, // placeholder for unavailable information
    batteryCapacity: 55.4,
    topSpeed: 160, // estimated based on similar BYD models
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fm6%2Ftech-spec%2Fdynamic.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW', // if we don't have exact specs, we can remove or update later
      torque: '310 Nm', // if we don't have exact specs, we can remove or update later
      drive: 'Front-Wheel Drive',
      seating: 7,
      cargo: '440 L' // if we don't have exact specs, we can update later
    }
  },
  {
    id: 'm6_extended',
    name: 'M6 Extended',
    brand: 'BYD',
    type: 'SUV',
    year: 2024,
    price: 929900,
    range: 530,
    acceleration: 0, // placeholder for unavailable information
    batteryCapacity: 71.8,
    topSpeed: 160, // estimated based on similar BYD models
    image: 'https://www.reverautomotive.com/_next/image?url=%2Fimages%2Fmodel%2Fm6%2Ftech-spec%2Fpremium.png&w=256&q=100',
    gallery: [],
    specs: {
      power: '150 kW', // if we don't have exact specs, we can remove or update later
      torque: '310 Nm', // if we don't have exact specs, we can remove or update later
      drive: 'Front-Wheel Drive',
      seating: 7,
      cargo: '440 L' // if we don't have exact specs, we can update later
    }
  },
  {
    id: 'model3_rwd',
    name: 'Model 3 Rear-Wheel Drive',
    brand: 'Tesla',
    type: 'Sedan',
    year: 2024,
    price: 1599000,
    range: 513,
    acceleration: 6.1,
    batteryCapacity: 57.5, // if you have the exact battery capacity, please provide
    topSpeed: 201,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MT351,$PR01,$W38A,$IPB2,$DRRH&view=STUD_FRONT34&model=m3&size=1920&bkba_opt=2&crop=100,0,100,0&overlay=0&zoom=2.5',
    gallery: [
      'https://images.unsplash.com/photo-1536700503339-1e4b06520771?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1571127236794-81c2bca55aea?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '283 hp',
      torque: '330 lb-ft',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '23 cu ft'
    }
  },
  {
    id: 'model3_lr',
    name: 'Model 3 Long Range',
    brand: 'Tesla',
    type: 'Sedan',
    year: 2024,
    price: 1899000,
    range: 629,
    acceleration: 4.4,
    batteryCapacity: 75, // if you have the exact capacity
    topSpeed: 201,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MT352,$PPSW,$W38A,$IPB3,$DRRH&view=STUD_FRONT34&model=m3&size=1920&bkba_opt=2&crop=0,0,0,0&overlay=0&',
    gallery: [
      'https://images.unsplash.com/photo-1536700503339-1e4b06520771?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1571127236794-81c2bca55aea?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '346 hp',
      torque: '376 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '23 cu ft'
    }
  },
  {
    id: 'model3_performance',
    name: 'Model 3 Performance',
    brand: 'Tesla',
    type: 'Sedan',
    year: 2024,
    price: 2199000,
    range: 528,
    acceleration: 3.1,
    batteryCapacity: 79,
    topSpeed: 262,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MT353,$PBSB,$W30P,$IPB4,$DRRH&view=STUD_FRONT34&model=m3&size=1920&bkba_opt=2&crop=0,0,0,0&overlay=0&',
    gallery: [
      'https://images.unsplash.com/photo-1536700503339-1e4b06520771?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1571127236794-81c2bca55aea?auto=format&fit=crop&w=800&q=80'
    ],
    specs: {
      power: '450 hp',
      torque: '471 lb-ft',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '23 cu ft'
    }
  },
  {
    id: 'modely_rwd',
    name: 'Model Y Rear-Wheel Drive',
    brand: 'Tesla',
    type: 'SUV',
    year: 2024,
    price: 1749000,
    range: 455,
    acceleration: 6.9,
    batteryCapacity: 57.5,
    topSpeed: 217,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MTY32,$PBSB,$WY19C,$INPB0,$DRRH&view=FRONT34&model=my&size=1920&bkba_opt=2&crop=0,0,0,0&overlay=0&',
    gallery: [],
    specs: {
      power: '347 hp',
      torque: '420 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '2,158 L'  // This is combined cargo space, update if you have different numbers
    }
  },
  {
    id: 'modely_lr',
    name: 'Model Y Long Range',
    brand: 'Tesla',
    type: 'SUV',
    year: 2024,
    price: 2049000,
    range: 533,
    acceleration: 5.0,
    batteryCapacity: 75.0,
    topSpeed: 217,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MTY33,$PN01,$WY19C,$INPB0,$DRRH&view=FRONT34&model=my&size=1920&bkba_opt=2&crop=0,0,0,0&overlay=0&',
    gallery: [],
    specs: {
      power: '514 hp',
      torque: '493 Nm',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '2,158 L'  // Combined cargo space, update if you have different numbers
    }
  },
  {
    id: 'modely_performance',
    name: 'Model Y Performance',
    brand: 'Tesla',
    type: 'SUV',
    year: 2024,
    price: 2329000,
    range: 514,
    acceleration: 3.7,
    batteryCapacity: 75.0,
    topSpeed: 250,
    image: 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MTY31,$PPSW,$W40B,$IPB1,$DV4W&view=FRONT34&model=my&size=1920&bkba_opt=2&crop=0,0,0,0&overlay=0&zoom=2',
    gallery: [],
    specs: {
      power: '534 hp',
      torque: '660 Nm',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '2,158 L'  // Combined cargo space, update if you have different numbers
    }
  },
  {
    id: 'ora07_lr',
    name: '07 Long Range',
    brand: 'Ora',
    type: 'Sedan',
    year: 2024,
    price: 1089000,
    range: 640,
    acceleration: 0, // If you have the 0-100 km/h time, please provide
    batteryCapacity: 84,
    topSpeed: 170,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ORA07/ORA07-P6-002.png',
    gallery: [],
    specs: {
      power: '204 hp',
      torque: '340 Nm',
      drive: 'Front-Wheel Drive', // Please confirm if this is correct
      seating: 5,
      cargo: '420 L' // If you have the exact cargo capacity, please provide
    }
  },
  {
    id: 'ora07_performance',
    name: '07 Performance',
    brand: 'Ora',
    type: 'Sedan',
    year: 2024,
    price: 1289000,
    range: 550,
    acceleration: 4.3, // If you have the 0-100 km/h time, please provide
    batteryCapacity: 84,
    topSpeed: 180,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ORA07/ORA07-P6-001.png',
    gallery: [],
    specs: {
      power: '408 hp',
      torque: '680 Nm',
      drive: 'All-Wheel Drive', // Assuming AWD due to high power/torque, please confirm
      seating: 5,
      cargo: '420 L' // If you have the exact cargo capacity, please provide
    }
  },
  {
    id: 'ora07_lr_ultra',
    name: '07 Long Range Ultra',
    brand: 'Ora',
    type: 'Sedan',
    year: 2024,
    price: 1189000,
    range: 640,
    acceleration: 0,
    batteryCapacity: 84,
    topSpeed: 170,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ORA07/ORA07-P6-003.png',
    gallery: [],
    specs: {
      power: '204 hp',
      torque: '340 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '420 L'
    }
  },
  {
    id: 'goodcat_pro',
    name: 'Good Cat Pro',
    brand: 'Ora',
    type: 'Hatchback',
    year: 2024,
    price: 649000,
    range: 480,
    acceleration: 0, // If you have the 0-100 km/h time, please provide
    batteryCapacity: 57.7,
    topSpeed: 160,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ora/ORA-PRO-Verdant-Green.png',
    gallery: [],
    specs: {
      power: '143 hp',
      torque: '210 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '228 L' // If you have a different cargo capacity, please provide
    }
  },
  {
    id: 'goodcat_ultra',
    name: 'Good Cat Ultra',
    brand: 'Ora',
    type: 'Hatchback',
    year: 2024,
    price: 749000,
    range: 480,
    acceleration: 0, // If you have the 0-100 km/h time, please provide
    batteryCapacity: 57.7,
    topSpeed: 160,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ora/ORA-ULTRA-Beige.png',
    gallery: [],
    specs: {
      power: '143 hp',
      torque: '210 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '228 L' // If you have a different cargo capacity, please provide
    }
  },
  {
    id: 'goodcat_gt',
    name: 'Good Cat GT',
    brand: 'Ora',
    type: 'Hatchback',
    year: 2024,
    price: 889000,
    range: 460,
    acceleration: 0, // If you have the 0-100 km/h time, please provide
    batteryCapacity: 57.7,
    topSpeed: 160,
    image: 'https://newretailwebsite.s3.ap-southeast-1.amazonaws.com/public/images/ora/ORA-GT-black.png',
    gallery: [],
    specs: {
      power: '171 hp',
      torque: '250 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '228 L' // If you have a different cargo capacity, please provide
    }
  },
  {
    id: 'aion_y_plus_410_premium',
    name: 'Y Plus 410 Premium',
    brand: 'AION',
    type: 'SUV',
    year: 2024,
    price: 859900,
    range: 410,
    acceleration: 8.5,
    batteryCapacity: 50.66,
    topSpeed: 160,
    image: 'https://static.gacne.com.cn/Public/Uploads/Picture/images/2022/09/744263488778174475721881382541.png',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '225 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '410 L'
    }
  },
  {
    id: 'aion_y_plus_490_elite',
    name: 'Y Plus 490 Elite',
    brand: 'AION',
    type: 'SUV',
    year: 2024,
    price: 899900,
    range: 490,
    acceleration: 8.5,
    batteryCapacity: 63.2,
    topSpeed: 160,
    image: 'https://static.gacne.com.cn/Public/Uploads/Picture/images/2022/09/467839393124876266526784919759.png',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '225 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '410 L'
    }
  },
  {
    id: 'aion_y_plus_490_premium',
    name: 'Y Plus 490 Premium',
    brand: 'AION',
    type: 'SUV',
    year: 2024,
    price: 949900,
    range: 490,
    acceleration: 8.5,
    batteryCapacity: 63.2,
    topSpeed: 160,
    image: 'https://static.gacne.com.cn/Public/Uploads/Picture/images/2022/09/334669436135152296945181592747.png',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '225 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '410 L'
    }
  },
  {
    id: 'aion_es',
    name: 'Aion ES',
    brand: 'AION',
    type: 'Sedan',
    year: 2024,
    price: 850000,
    range: 442,
    acceleration: 12.1,
    batteryCapacity: 55.2,
    topSpeed: 130,
    image: 'https://evme.io/_next/image?url=https%3A%2F%2Fmicro-site-cms.s3.ap-southeast-1.amazonaws.com%2Fnew-car%2Fbrands%2Faion%2Fmodels%2Fes%2Fsubmodels%2Fprivate%2Fexteriors%2Fprivate_d.jpg&w=1780&q=75',
    gallery: [],
    specs: {
      power: '136 hp',
      torque: '225 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '420 L'
    }
  },
  {
    id: 'aion_es_taxi',
    name: 'Aion ES Taxi EVme',
    brand: 'AION',
    type: 'Sedan',
    year: 2024,
    price: 929900,
    range: 442,
    acceleration: 12.1,
    batteryCapacity: 55.2,
    topSpeed: 130,
    image: 'https://evme.io/_next/image?url=https%3A%2F%2Fmicro-site-cms.s3.ap-southeast-1.amazonaws.com%2Fnew-car%2Fbrands%2Faion%2Fmodels%2Fes%2Ffeatures%2Ffeature_1.jpg&w=1780&q=75',
    gallery: [],
    specs: {
      power: '136 hp',
      torque: '225 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '420 L'
    }
  },
  {
    id: 'ex30_single',
    name: 'EX30 Single Motor Extended Range',
    brand: 'Volvo',
    type: 'SUV',
    year: 2024,
    price: 1590000,
    range: 540,
    acceleration: 5.3,
    batteryCapacity: 69,
    topSpeed: 289,
    image: 'https://d3ctwcmba8zv33.cloudfront.net/product/186/dmKWGC6LPaxJoDkhjb2TZVGUm74VYDxiGfdwD0oS.jpg',
    gallery: [],
    specs: {
      power: '272 hp',
      torque: '343 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '318 L'
    }
  },
  {
    id: 'ex30_twin',
    name: 'EX30 Twin Motor Performance',
    brand: 'Volvo',
    type: 'SUV',
    year: 2024,
    price: 1890000,
    range: 520,
    acceleration: 3.6,
    batteryCapacity: 69,
    topSpeed: 289,
    image: 'https://d3ctwcmba8zv33.cloudfront.net/product/201/owe4TAcGYHo4RtHzK6ZnohoWhAcShUHBl8QSaekD.jpg',
    gallery: [],
    specs: {
      power: '428 hp',
      torque: '543 Nm',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '318 L'
    }
  },
  {
    id: 'ex40_single',
    name: 'EX40 Single Motor',
    brand: 'Volvo',
    type: 'SUV',
    year: 2024,
    price: 1990000,
    range: 565,
    acceleration: 7.3,
    batteryCapacity: 69,
    topSpeed: 289,
    image: 'https://d3ctwcmba8zv33.cloudfront.net/product/282/lRwnr9H3UUqEK7q7srKvsEUzcM6Fmg036eutdpxx.jpg',
    gallery: [],
    specs: {
      power: '238 hp',  // Based on typical Volvo single motor specs
      torque: '330 Nm',  // Based on typical Volvo single motor specs
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '419 L'  // Standard EX40 cargo capacity
    }
  },
  {
    id: 'ex40_twin',
    name: 'EX40 Twin Motor',
    brand: 'Volvo',
    type: 'SUV',
    year: 2024,
    price: 2690000,
    range: 645,
    acceleration: 4.8,
    batteryCapacity: 82,
    topSpeed: 289,
    image: 'https://d3ctwcmba8zv33.cloudfront.net/product/278/Yqj2PDZAAqTeHAfe4mk94ACr4FCApzHu21fmiKjh.jpg',
    gallery: [],
    specs: {
      power: '408 hp',  // Based on typical Volvo twin motor specs
      torque: '660 Nm',  // Based on typical Volvo twin motor specs
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '419 L'  // Standard EX40 cargo capacity
    }
  },
  {
    id: 'mg4_standard_d',
    name: 'MG4 STANDARD RANGE (D)',
    brand: 'MG',
    type: 'Hatchback',
    year: 2024,
    price: 709900,
    range: 423,
    acceleration: 7.5,
    batteryCapacity: 49,
    topSpeed: 161,
    image: 'https://static.mgcars.com/assets/images/2024/03/25/2024032513311175.png',
    gallery: [],
    specs: {
      power: '170 hp',
      torque: '250 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '363 L'
    }
  },
  {
    id: 'mg4_standard_x',
    name: 'MG4 STANDARD RANGE (X)',
    brand: 'MG',
    type: 'Hatchback',
    year: 2024,
    price: 809900,
    range: 423,
    acceleration: 7.5,
    batteryCapacity: 49,
    topSpeed: 161,
    image: 'https://static.mgcars.com/assets/images/2024/03/25/2024032513461498.png',
    gallery: [],
    specs: {
      power: '170 hp',
      torque: '250 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '363 L'
    }
  },
  {
    id: 'mg4_long_range_v',
    name: 'MG4 LONG RANGE (V)',
    brand: 'MG',
    type: 'Hatchback',
    year: 2024,
    price: 889000,
    range: 540,
    acceleration: 7.5,
    batteryCapacity: 64,
    topSpeed: 161,
    image: 'https://static.mgcars.com/assets/images/2024/03/25/2024032513393932.png',
    gallery: [],
    specs: {
      power: '203 hp',
      torque: '250 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '363 L'
    }
  },
  {
    id: 'mg4_xpower',
    name: 'MG4 XPOWER',
    brand: 'MG',
    type: 'Hatchback',
    year: 2024,
    price: 1119900,
    range: 480,
    acceleration: 3.8,
    batteryCapacity: 64,
    topSpeed: 199,
    image: 'https://static.mgcars.com/assets/images/2024/03/25/2024032513404250.png',
    gallery: [],
    specs: {
      power: '435 hp',
      torque: '600 Nm',
      drive: 'All-Wheel Drive',
      seating: 5,
      cargo: '363 L'
    }
  },
  {
    id: 'maxus7_x',
    name: 'Maxus 7 (X)',
    brand: 'MG',
    type: 'SUV',
    year: 2024,
    price: 1769000,
    range: 570,
    acceleration: 0, // placeholder
    batteryCapacity: 90,
    topSpeed: 180,
    image: 'https://static.mgcars.com/assets/images/2024/06/14/2024061418003627.png',
    gallery: [],
    specs: {
      power: '245 hp',
      torque: '350 Nm',
      drive: 'Front-Wheel Drive',
      seating: 7,
      cargo: '600 L'
    }
  },
  {
    id: 'maxus9_x',
    name: 'Maxus 9 (X)',
    brand: 'MG',
    type: 'SUV',
    year: 2024,
    price: 2499000,
    range: 540,
    acceleration: 0, // placeholder
    batteryCapacity: 90,
    topSpeed: 180,
    image: 'https://static.mgcars.com/assets/images/2023/05/02/2023050210001167.png',
    gallery: [],
    specs: {
      power: '245 hp',
      torque: '350 Nm',
      drive: 'Front-Wheel Drive',
      seating: 7,
      cargo: '640 L'
    }
  },
  {
    id: 'maxus9_v',
    name: 'Maxus 9 (V)',
    brand: 'MG',
    type: 'SUV',
    year: 2024,
    price: 2699000,
    range: 540,
    acceleration: 0, // placeholder
    batteryCapacity: 90,
    topSpeed: 180,
    image: 'https://static.mgcars.com/assets/images/2023/05/02/2023050209595374.png',
    gallery: [],
    specs: {
      power: '245 hp',
      torque: '350 Nm',
      drive: 'Front-Wheel Drive',
      seating: 7,
      cargo: '640 L'
    }
  },
  {
    id: 'mg_cyberster',
    name: 'Cyberster',
    brand: 'MG',
    type: 'Sports',
    year: 2024,
    price: 2499000,
    range: 503,
    acceleration: 3.2,
    batteryCapacity: 77,
    topSpeed: 201,
    image: 'https://static.mgcars.com/assets/images/2024/03/25/2024032513521950.png',
    gallery: [],
    specs: {
      power: '544 hp',
      torque: '725 Nm',
      drive: 'All-Wheel Drive',
      seating: 2,
      cargo: '200 L'
    }
  },
  {
    id: 'zs_ev_d',
    name: 'ZS EV (D)',
    brand: 'MG',
    type: 'SUV',
    year: 2024,
    price: 829000,
    range: 403,
    acceleration: 8.6,
    batteryCapacity: 50.3,
    topSpeed: 174,
    image: 'https://static.mgcars.com/assets/images/2022/03/04/2022030417025895.png',
    gallery: [],
    specs: {
      power: '177 hp',
      torque: '280 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '448 L'
    }
  },
  {
    id: 'zs_ev_x',
    name: 'ZS EV (X)',
    brand: 'MG',
    type: 'SUV',
    year: 2024,
    price: 899900,
    range: 403,
    acceleration: 8.6,
    batteryCapacity: 50.3,
    topSpeed: 174,
    image: 'https://static.mgcars.com/assets/images/2022/03/04/2022030417015068.png',
    gallery: [],
    specs: {
      power: '177 hp',
      torque: '280 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '448 L'
    }
  },
  {
    id: 'deepal_s07',
    name: 'S07',
    brand: 'Deepal',
    type: 'SUV',
    year: 2024,
    price: 1199000,
    range: 485,
    acceleration: 6.7,
    batteryCapacity: 66.8,
    topSpeed: 180,
    image: 'https://api.changan.co.th/uploads/01_Gray_4d7ec41210_4cbe7e3c8f.png?w=1920&q=75', // Add image URL when available
    gallery: [],
    specs: {
      power: '218 hp', // If you have exact specs, please update
      torque: '310 Nm', // If you have exact specs, please update
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '500 L' // If you have exact cargo capacity, please update
    }
  },
  {
    id: 'deepal_s07_l',
    name: 'S07 L',
    brand: 'Deepal',
    type: 'SUV',
    year: 2024,
    price: 1499000,
    range: 560,
    acceleration: 6.7,
    batteryCapacity: 79.97,
    topSpeed: 180,
    image: 'https://api.changan.co.th/uploads/Eclipse_Black_S07_c583c7c17c.png?w=1920&q=75', // Add image URL when available
    gallery: [],
    specs: {
      power: '218 hp', // If you have exact specs, please update
      torque: '310 Nm', // If you have exact specs, please update
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '500 L' // If you have exact cargo capacity, please update
    }
  },
  {
    id: 'deepal_l07',
    name: 'L07',
    brand: 'Deepal',
    type: 'Sedan',
    year: 2024,
    price: 1329000,
    range: 540,
    acceleration: 5.9,
    batteryCapacity: 66.8,
    topSpeed: 170,
    image: 'https://api.changan.co.th/uploads/Xingyunqing_02_f1c3d74e6c.png?w=1920&q=75', // Add image URL when available
    gallery: [],
    specs: {
      power: '218 hp', // If you have exact specs, please update
      torque: '310 Nm', // If you have exact specs, please update
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '500 L' // If you have exact cargo capacity, please update
    }
  },
  {
    id: 'deepal_l07_s',
    name: 'L07 S',
    brand: 'Deepal',
    type: 'Sedan',
    year: 2024,
    price: 1239000,
    range: 475,
    acceleration: 0, // Add when available
    batteryCapacity: 58.89,
    topSpeed: 170,
    image: 'https://api.changan.co.th/uploads/Comet_white_04_b4d29779d5.png?w=1920&q=75', // Add image URL when available
    gallery: [],
    specs: {
      power: '218 hp', // If you have exact specs, please update
      torque: '310 Nm', // If you have exact specs, please update
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '500 L' // If you have exact cargo capacity, please update
    }
  },
  {
    id: 'nissan_leaf',
    name: 'Leaf',
    brand: 'Nissan',
    type: 'Hatchback',
    year: 2024,
    price: 1590000,
    range: 311,
    acceleration: 7.9,
    batteryCapacity: 40,
    topSpeed: 144,
    image: 'https://en.nissan.co.th/content/dam/Nissan/th/vehicles/VLP/leaf/360/exterior/XEH/04.jpg.ximg.c1h.360.jpg',
    gallery: [],
    specs: {
      power: '150 hp',
      torque: '320 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '435 L'
    }
  },
  {
    id: 'volkswagen_id_buzz',
    name: 'ID. Buzz',
    brand: 'Volkswagen',
    type: 'Van',
    year: 2024,
    price: 4490000,
    range: 423, // WLTP range
    acceleration: 10.2,
    batteryCapacity: 82,
    topSpeed: 145,
    image: 'https://assets.volkswagen.com/is/image/volkswagenag/VW_NGW6_Showroom_IDBuzz_FocusTeaser_Wrapmate_2024jpg?Zml0PWNyb3AsMSZmbXQ9d2VicCZxbHQ9Nzkmd2lkPTE5MjAmaGVpPTEwODAmYWxpZ249MC4wMCwwLjAwJmJmYz1vZmYmM2E1Nw==',
    gallery: [],
    specs: {
      power: '204 hp',
      torque: '310 Nm',
      drive: 'Rear-Wheel Drive',
      seating: 5,
      cargo: '1121 L' // Expandable to 2205L with seats folded
    }
  },
  {
    id: 'neta_x_smart',
    name: 'X Smart',
    brand: 'Neta',
    type: 'SUV',
    year: 2024,
    price: 799000,
    range: 480,
    acceleration: 9.5,
    batteryCapacity: 62,
    topSpeed: 150,
    image: 'https://www.neta.co.th/assets/img/neta-x/360/onyx-black/neta-x-static.png',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '500 L'
    }
  },
  {
    id: 'neta_x_comfort',
    name: 'X Comfort',
    brand: 'Neta',
    type: 'SUV',
    year: 2024,
    price: 739000,
    range: 401,
    acceleration: 9.5,
    batteryCapacity: 51.8,
    topSpeed: 150,
    image: 'https://www.neta.co.th/assets/img/neta-x/360/glacier-blue/neta-x-static.png',
    gallery: [],
    specs: {
      power: '150 kW',
      torque: '310 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '500 L'
    }
  },
  {
    id: 'neta_v_ii_smart',
    name: 'V II Smart',
    brand: 'Neta',
    type: 'SUV',
    year: 2024,
    price: 549000,
    range: 382,
    acceleration: 13.0,
    batteryCapacity: 36.1,
    topSpeed: 124,
    image: 'https://neta.co.th/assets/img/netav2/360/milktea/20_342degree.png',
    gallery: [],
    specs: {
      power: '95 kW',
      torque: '150 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '400 L'
    }
  },
  {
    id: 'neta_v_ii_lite',
    name: 'V II Lite',
    brand: 'Neta',
    type: 'SUV',
    year: 2024,
    price: 499000,
    range: 382,
    acceleration: 13.0,
    batteryCapacity: 36.1,
    topSpeed: 124,
    image: 'https://neta.co.th/assets/img/netav2/360/gray/20_342degree.png',
    gallery: [],
    specs: {
      power: '95 kW',
      torque: '150 Nm',
      drive: 'Front-Wheel Drive',
      seating: 5,
      cargo: '400 L'
    }
  }
];
