import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const CompareBrands = () => {
  const { brand1, brand2 } = useParams<{ brand1: string; brand2: string }>();
  
  // Ensure consistent URL order
  const [canonicalBrand1, canonicalBrand2] = [brand1, brand2].sort();
  const canonicalUrl = `https://rodfaifa.com/compare-brands/${canonicalBrand1}-vs-${canonicalBrand2}`;

  // Redirect if URL is not canonical
  if (brand1 !== canonicalBrand1) {
    return <Navigate to={`/compare-brands/${canonicalBrand1}-vs-${canonicalBrand2}`} replace />;
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div>
        <h1>Comparison: {canonicalBrand1} vs {canonicalBrand2}</h1>
        {/* Comparison Page Content */}
      </div>
    </>
  );
};

export default CompareBrands;