import React from 'react';
import { X, Battery, Gauge, Timer, BanknoteIcon } from 'lucide-react';
import { EVehicle } from '../data/vehicles';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currency';

interface ComparisonPanelProps {
  vehicles: EVehicle[];
  onRemove: (id: string) => void;
}

export default function ComparisonPanel({ vehicles, onRemove }: ComparisonPanelProps) {
  const { t, i18n } = useTranslation();

  if (vehicles.length === 0) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-4 z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">{t('comparison.title')} ({vehicles.length}/3)</h3>
          <button
            onClick={() => vehicles.forEach(v => onRemove(v.id))}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            {t('comparison.clearAll')}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {vehicles.map(vehicle => (
            <div key={vehicle.id} className="relative bg-gray-50 rounded-lg p-4">
              <button
                onClick={() => onRemove(vehicle.id)}
                className="absolute top-2 right-2 p-1 hover:bg-gray-200 rounded-full"
              >
                <X className="w-4 h-4" />
              </button>

              <div className="mb-3">
                <img
                  src={vehicle.image}
                  alt={vehicle.name}
                  className="w-full h-32 object-cover rounded-lg mb-2"
                />
                <h4 className="font-medium">{vehicle.brand} {vehicle.name}</h4>
                <p className="text-sm text-gray-500">{t(`types.${vehicle.type}`)}</p>
              </div>

              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <BanknoteIcon className="w-4 h-4 text-gray-500" />
                    <span className="text-sm">{t('vehicle.price')}</span>
                  </div>
                  <span className="text-sm font-medium">{formatCurrency(vehicle.price, i18n.language)}</span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Gauge className="w-4 h-4 text-gray-500" />
                    <span className="text-sm">{t('vehicle.range')}</span>
                  </div>
                  <span className="text-sm font-medium">
                    {vehicle.range} {i18n.language === 'th' ? 'กม.' : 'mi'}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Timer className="w-4 h-4 text-gray-500" />
                    <span className="text-sm">{t('vehicle.acceleration')}</span>
                  </div>
                  <span className="text-sm font-medium">{vehicle.acceleration}s</span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Battery className="w-4 h-4 text-gray-500" />
                    <span className="text-sm">{t('vehicle.battery')}</span>
                  </div>
                  <span className="text-sm font-medium">{vehicle.batteryCapacity} kWh</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}