export default {
  header: {
    title: "RODFAIFA",
    vehiclesFound: "{{count}} vehicles found",
    quiz: "Quiz" // Add this line
  },
  filters: {
    search: "Search vehicles...",
    priceRange: "Price Range",
    min: "Min",
    max: "Max",
    batteryCapacity: "Battery Capacity (kWh)",
    vehicleType: "Vehicle Type",
    make: "Make",
    sortBy: "Sort By"
  },
  sorting: {
    "price-asc": "Price: Low to High",
    "price-desc": "Price: High to Low",
    "range-desc": "Range: High to Low",
    "acceleration-asc": "Acceleration: Fastest First"
  },
  comparison: {
    title: "Compare Vehicles",
    clearAll: "Clear All"
  },
  vehicle: {
    compare: "Compare",
    added: "Added",
    specifications: "Specifications",
    range: "Range",
    acceleration: "0-100 km/h",
    battery: "Battery",
    topSpeed: "Top Speed",
    year: "Year",
    price: "Price",
    power: "Power",
    torque: "Torque",
    drive: "Drive",
    seating: "Seating",
    cargo: "Cargo Space"
  },
  types: {
    SUV: "SUV",
    Sedan: "Sedan",
    Hatchback: "Hatchback",
    Truck: "Truck",
    Sports: "Sports Car",
    Van: "Van"
  },
  contact: {
    title: "Contact Us",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send Message",
    sending: "Sending...",
    success: "Message sent successfully!",
    error: "Failed to send message. Please try again."
  },
  experience: {
    title: "User Experiences",
    addExperience: "Share Experience",
    searchExperiences: "Search experiences...",
    filterByMake: "Filter by Make",
    resultsFound: "{{count}} experiences found",
    shareYourThoughts: "Share Your Thoughts",
    selectVehicle: "Select Vehicle",
    positive: "Positive",
    negative: "Negative",
    yourExperience: "Your Experience",
    sharePlaceholder: "Share your experience with this vehicle...",
    yourName: "Your Name",
    submitting: "Submitting...",
    submit: "Submit Experience",
    noResults: "No experiences found matching your criteria",
    submitSuccess: "Experience shared successfully!",
    submitError: "Failed to submit experience. Please try again.",
    enterAdminKey: "Enter admin key",
    confirmDelete: "Are you sure you want to delete this experience?",
    deleteError: "Failed to delete experience",
    delete: "Delete experience"
  },
  seo: {
    comparePage: {
      title: "Compare {{car1}} vs {{car2}} | EV Comparison Thailand",
      description: "Detailed comparison between {{car1}} and {{car2}}. Compare prices, range, features and specifications in Thailand."
    },
    brandPage: {
      title: "{{brand}} Electric Vehicles in Thailand | Prices & Specs",
      description: "Explore {{brand}} electric vehicles available in Thailand. View prices, specifications, and real owner reviews."
    }
  },
  categories: {
    SUV: {
      title: "Best Electric SUVs in Thailand 2024 | Compare Prices & Features",
      description: "Compare all electric SUVs available in Thailand. Find the best EV SUV with detailed specifications, prices, and real owner reviews.",
      metaKeywords: "electric SUV, EV SUV Thailand, best electric SUV 2024"
    },
    Sedan: {
      title: "Electric Sedans in Thailand | Compare EV Sedan Models & Prices",
      description: "Complete guide to electric sedans in Thailand. Compare prices, range, and features of all available EV sedans.",
      metaKeywords: "electric sedan, EV sedan Thailand, Tesla Model 3, BYD Seal"
    },
    // ... other categories ...
  },
  brandComparison: {
    pageTitle: "{{brand1}} vs {{brand2}} EV Comparison Thailand (2024)",
    pageDescription: "Compare {{brand1}} and {{brand2}} electric vehicles in Thailand. Price comparison, specifications, and features of all available models.",
    heading: "{{brand1}} vs {{brand2}} Comparison",
    subheading: "Compare specifications, prices, and features of all available models",
    detailedComparison: "Detailed Comparison",
    keyDifferences: "Key Differences",
    priceComparison: "Price Comparison",
    rangeComparison: "Range Comparison",
    featuresComparison: "Features Comparison",
    faqTitle: "Frequently Asked Questions",
    relatedComparisons: "Related Comparisons",
    availableModels: "Available Models",
    priceRange: "Price Range",
    specifications: "Specifications",
    price: "Price",
    range: "Range",
    batteryCapacity: "Battery Capacity",
    acceleration: "Acceleration",
    compareModels: "Compare Models",
    faq: {
      whichBetter: "Which is better: {{brand1}} or {{brand2}}?",
      whichBetterAnswer: "Both {{brand1}} and {{brand2}} offer compelling electric vehicles. {{brand1}} models range from {{price1}}, while {{brand2}} models range from {{price2}}. The choice depends on your specific needs, budget, and preferences.",
      priceRange: "How do {{brand1}} and {{brand2}} prices compare?",
      priceRangeAnswer: "{{brand1}} models are priced from {{price1}}, while {{brand2}} models start from {{price2}}.",
      warranty: "What warranty do {{brand1}} and {{brand2}} offer?",
      warrantyAnswer: "Both manufacturers offer comprehensive warranty packages. Please check with local dealers for specific terms and conditions."
    },
    errors: {
      invalid: {
        title: "Invalid Comparison",
        description: "Please select valid brands to compare"
      },
      notFound: {
        title: "Brands Not Found",
        description: "One or both brands could not be found"
      }
    },
    priceComparisonText: "{{brand1}} models are priced at an average of {{price1}}, while {{brand2}} models average {{price2}}. The price difference between these brands is {{difference}}.",
    rangeComparisonText: "{{brand1}}'s best range is {{range1}} km, compared to {{brand2}}'s {{range2}} km - a difference of {{difference}} km.",
    featuresComparisonText: "{{brand1}} offers {{specs1}}, while {{brand2}} comes with {{specs2}}."
  },
  common: {
    backToHome: "Back to Home",
    loading: "Loading...",
    error: "Error",
    notFound: "Not Found"
  },
  errors: {
    invalidComparison: "Invalid Comparison",
    invalidComparisonDesc: "Please select two different brands to compare.",
    brandsNotFound: "Brands Not Found",
    brandsNotFoundDesc: "We couldn't find the brands you're looking for. Please try different brands.",
  },
  // **New Quiz Translations**
  quiz: {
    title: "Find Your Ideal EV Car",
    consentText: "We collect your answers to provide personalized recommendations. Do you agree?",
    agree: "I Agree",
    questionBudget: "What is your budget (in Baht)?",
    questionRange: "What minimum range do you require (in km)?",
    questionUsage: "What is your primary usage?",
    questionSeating: "How many seats do you need?",
    next: "Next",
    recommendations: "Recommended EV Cars for You",
    noRecommendations: "No vehicles match your criteria. Please adjust your preferences.",
    step: "Step {{current}} of {{total}}",
    select: "Select an option",
    usageOptions: {
      city: "City Driving",
      highway: "Highway Driving",
      mixed: "Mixed City and Highway"
    },
    seatingOptions: {
      '2': "2 seats",
      '4': "4 seats",
      '5': "5 seats",
      '7': "7 seats"
    },
    showResults: "Show Recommendations",
    startOver: "Start Over",
    questionType: "What type of vehicle are you looking for?"
  }
};
