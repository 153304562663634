import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { SEO_CONFIG, Locale } from '../i18n/seoConfig';

export interface SEOProps {
  title: string;
  description: string;
  locale: Locale;
  canonicalPath: string;
  type?: 'website' | 'article';
  headers?: Record<string, string>;
  brand1?: string;  // Make brand names optional
  brand2?: string;
}

export const SEOHead: React.FC<SEOProps> = ({
  title,
  description,
  locale,
  canonicalPath,
  type = 'website',
  headers,
  brand1,
  brand2
}) => {
  const location = useLocation();
  const currentPath = canonicalPath || location.pathname;
  const baseUrl = 'https://rodfaifa.com';
  
  const seoTitle = title || SEO_CONFIG[locale].defaultTitle;
  const seoDescription = description || SEO_CONFIG[locale].defaultDescription;
  const canonicalUrl = `${baseUrl}${currentPath}`;
  
  // Generate alternate URLs for each language
  const alternateUrls = Object.entries(SEO_CONFIG).map(([lang, config]) => ({
    lang,
    url: `${baseUrl}${config.routes.home}${currentPath}`
  }));

  // Generate breadcrumb schema only if we have brand names
  const breadcrumbSchema = brand1 && brand2 ? {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": baseUrl
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Compare",
        "item": `${baseUrl}/compare`
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": `${brand1} vs ${brand2}`,
        "item": canonicalUrl
      }
    ]
  } : null;

  return (
    <Helmet>
      <html lang={locale} />
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Language alternates */}
      {alternateUrls.map(({ lang, url }) => (
        <link 
          key={lang} 
          rel="alternate" 
          hrefLang={lang} 
          href={url} 
        />
      ))}
      
      {/* Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          name: seoTitle,
          description: seoDescription,
          url: canonicalUrl,
          inLanguage: [locale],
          alternateName: locale === 'en' ? 'รถไฟฟ้า' : 'Rodfaifa'
        })}
      </script>

      {/* Breadcrumb Schema - only added for comparison pages */}
      {breadcrumbSchema && (
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>
      )}
      
      {/* Cache headers */}
      {headers && Object.entries(headers).map(([key, value]) => (
        <meta key={key} httpEquiv={key} content={value} />
      ))}
      
      {/* Add these meta tags in SEOHead */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta property="og:image" content={`https://rodfaifa.com/og-images/${brand1}-vs-${brand2}.jpg`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          'name': 'RODFAIFA',
          'url': 'https://rodfaifa.com',
          'potentialAction': {
            '@type': 'SearchAction',
            'target': 'https://rodfaifa.com/?search={search_term_string}',
            'query-input': 'required name=search_term_string'
          }
        })}
      </script>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "RODFAIFA",
          "url": "https://rodfaifa.com/",
          "description": "Discover and compare EV cars in Thailand.",
          "inLanguage": locale,
        })}
      </script>

      {/* In the SEOHead component for the quiz page */}
      <script type="application/ld+json">
        {JSON.stringify({
          title: 'EV Car Quiz - Find Your Ideal Electric Car in Thailand | RODFAIFA',
          description: 'Take our interactive quiz to find the perfect electric car in Thailand that matches your needs and preferences.',
        })}
      </script>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Quiz",
          "name": "EV Car Quiz",
          "description": "Find your ideal electric car in Thailand by taking our quiz.",
          "datePublished": "2023-10-01",
          "url": "https://rodfaifa.com/quiz",
          "author": {
            "@type": "Organization",
            "name": "RODFAIFA"
          }
        })}
      </script>
    </Helmet>
  );
};
