import './i18n';
import React, { useState, useMemo, Suspense } from 'react';
import { Zap, Menu } from 'lucide-react';
import { vehicles, EVehicle } from './data/vehicles';
import VehicleCard from './components/VehicleCard';
import ComparisonPanel from './components/ComparisonPanel';
import Filters from './components/Filters';
import LanguageSwitch from './components/LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { SEOHead } from './components/SEOHead';
import emailjs from '@emailjs/browser';
import { Locale } from './i18n/seoConfig';
import CompareBrands from './pages/CompareBrands';
import Sorry from './pages/Sorry';

// Lazy load components
const Experience = React.lazy(() => import('./pages/Experience'));
const Contact = React.lazy(() => import('./pages/Contact'));
const ElectricCarsThailand = React.lazy(() => import('./pages/ElectricCarsThailand'));
const Quiz = React.lazy(() => import('./pages/Quiz'));

// Initialize EmailJS
emailjs.init("lUupjW33Ww3-N0WR7");

// Home component remains the same
const Home: React.FC<{
  filteredVehicles: EVehicle[];
  comparedVehicles: EVehicle[];
  handleCompare: (vehicle: EVehicle) => void;
  setComparedVehicles: React.Dispatch<React.SetStateAction<EVehicle[]>>;  // Add this
}> = ({ filteredVehicles, comparedVehicles, handleCompare, setComparedVehicles }) => {
  return (
    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold">{`Explore EV Cars in Thailand`}</h2>
      <p className="mt-4 text-gray-700">
        {`Welcome to RODFAIFA.com, your premier source for comparing electric cars in Thailand. Find the perfect EV car tailored to your needs.`}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredVehicles.map((vehicle) => (
          <VehicleCard
            key={vehicle.id}
            vehicle={vehicle}
            onCompare={handleCompare}
            isCompared={comparedVehicles.some(v => v.id === vehicle.id)}
          />
        ))}
      </div>
      {comparedVehicles.length > 0 && (
        <ComparisonPanel 
          vehicles={comparedVehicles} 
          onRemove={(id) => {
            setComparedVehicles(prev => prev.filter(v => v.id !== id));
          }} 
        />
      )}
    </main>
  );
};

const AppContent: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 10000000]);
  const [batteryRange, setBatteryRange] = useState<[number, number]>([0, 200]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState('price-asc');
  const [comparedVehicles, setComparedVehicles] = useState<EVehicle[]>([]);
  const { t, i18n } = useTranslation();
  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);
  const locale = i18n.language as Locale;
  
  const location = useLocation();

  // Function to get SEO data based on current route
  const getSeoData = () => {
    switch (location.pathname) {
      case '/quiz':
        return {
          title: 'EV Car Quiz - Find Your Ideal Electric Car in Thailand | RODFAIFA',
          description: 'Take our interactive quiz to find the perfect electric car in Thailand that matches your needs and preferences.',
        };
      case '/experience':
        return {
          title: 'User Experiences | RODFAIFA',
          description: 'Read and share user experiences with various electric cars in Thailand.',
        };
      case '/contact':
        return {
          title: 'Contact Us | RODFAIFA',
          description: 'Get in touch with RODFAIFA for any inquiries or support.',
        };
      case '/electric-car-thailand':
        return {
          title: 'Electric Cars in Thailand - RODFAIFA',
          description: 'Discover and compare electric cars available in Thailand.',
        };
      default:
        return {
          title: t('header.title'),
          description: t('seo.defaultDescription'),
        };
    }
  };

  const seoData = getSeoData();

  const filteredVehicles = useMemo(() => {
    return vehicles
      .filter((vehicle) => {
        const matchesSearch =
          vehicle.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          vehicle.brand.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesPrice =
          vehicle.price >= priceRange[0] && vehicle.price <= priceRange[1];
        const matchesBattery =
          vehicle.batteryCapacity >= batteryRange[0] &&
          vehicle.batteryCapacity <= batteryRange[1];
        const matchesType =
          selectedTypes.length === 0 || selectedTypes.includes(vehicle.type);
        const matchesMake =
          selectedMakes.length === 0 || selectedMakes.includes(vehicle.brand);

        return (
          matchesSearch &&
          matchesPrice &&
          matchesBattery &&
          matchesType &&
          matchesMake
        );
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'price-asc':
            return a.price - b.price;
          case 'price-desc':
            return b.price - a.price;
          case 'range-desc':
            return b.range - a.range;
          case 'acceleration-asc':
            return a.acceleration - b.acceleration;
          default:
            return 0;
        }
      });
  }, [searchQuery, priceRange, batteryRange, selectedTypes, selectedMakes, sortBy]);

  const handleCompare = (vehicle: EVehicle) => {
    setComparedVehicles(prev => {
      // If vehicle is already compared, remove it
      if (prev.some(v => v.id === vehicle.id)) {
        return prev.filter(v => v.id !== vehicle.id);
      }
      // If not yet compared and less than 3 vehicles, add it
      if (prev.length < 3) {
        return [...prev, vehicle];
      }
      return prev;
    });
  };

  // Home component with state and handlers
  const HomeWithState = () => (
    <div>
      <Filters
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        batteryRange={batteryRange}
        setBatteryRange={setBatteryRange}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        selectedMakes={selectedMakes}
        setSelectedMakes={setSelectedMakes}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <Home
        filteredVehicles={filteredVehicles}
        comparedVehicles={comparedVehicles}
        handleCompare={handleCompare}
        setComparedVehicles={setComparedVehicles}  // Add this
      />
    </div>
  );

  return (
    <>
      {/* Single SEOHead component */}
      <SEOHead
        title={seoData.title}
        description={seoData.description}
        canonicalPath={window.location.pathname}
        locale={locale}
      />
      {/* Canonical link inside Helmet */}
      <Helmet>
        <link
          rel="canonical"
          href={`https://rodfaifa.com${location.pathname}`}
        />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-8">
                <Link 
                  to="/" 
                  className="flex items-center group hover:opacity-90 transition-opacity"
                  aria-label="Go to homepage"
                >
                  <Zap className="w-8 h-8 text-blue-600 group-hover:scale-110 transition-transform" />
                  <h1 className="ml-2 text-2xl font-bold text-gray-900">{t('header.title')}</h1>
                </Link>
              </div>
              <div className="flex items-center gap-4">
                <nav className="hidden md:flex space-x-6">
                  <Link to="/quiz" className="text-gray-600 hover:text-gray-900 transition-colors">
                    {t('header.quiz') || "Quiz"} {/* Ensure "header.quiz" exists in translations */}
                  </Link>
                  <Link to="/experience" className="text-gray-600 hover:text-gray-900 transition-colors">
                    {t('experience.title')}
                  </Link>
                  <Link to="/contact" className="text-gray-600 hover:text-gray-900 transition-colors">
                    {t('contact.title')}
                  </Link>
                </nav>
                <LanguageSwitch />
                <span className="text-sm text-gray-500">
                  {t('header.vehiclesFound', { count: filteredVehicles.length })}
                </span>
                {/* Mobile menu button */}
                <button
                  type="button"
                  className="md:hidden text-gray-500 hover:text-gray-900 focus:outline-none"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <Menu className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Routing */}
        <Suspense fallback={<div>{t('common.loading')}</div>}>
          <Routes>
            <Route path="/" element={<HomeWithState />} />
            <Route
              path="/compare-brands/:brand1-vs-:brand2"
              element={<CompareBrands />}
            />
            <Route path="/experience" element={<Experience />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/electric-car-thailand" element={<ElectricCarsThailand />} />
            <Route path="/quiz" element={<Quiz />} /> {/* Ensure this points to Quiz */}
            <Route path="/sorry" element={<Sorry />} />
            {/* Fallback Route for 404 */}
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
};

export default App;
