// src/pages/Sorry.tsx
import React, { useState } from 'react';
import { Frown, Send } from 'lucide-react';

export default function Sorry() {
  const [formData, setFormData] = useState({
    evOwnerName: '',
    evModel: '',
    socialPosts: '',
    offenderName: '',
    offendingVehicle: '',
    transgressions: new Set<string>(),
    apologyType: '',
    comments: ''
  });

  const [status, setStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('submitting');
    
    if (formData.evModel.toLowerCase().includes('tesla')) {
      setStatus('error');
      setErrorMessage('Please return when you are more superior.');
      return;
    }

    setTimeout(() => setStatus('success'), 1000);
  };

  return (
    <div className="relative max-w-4xl mx-auto p-8">
      {/* Watermark */}
      <div className="fixed inset-0 flex items-center justify-center pointer-events-none">
        <span className="text-[120px] text-gray-100 transform -rotate-45">PREMIUM</span>
      </div>

      <div className="relative z-10">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900">RODFAIFA GROUP</h1>
          <h2 className="text-2xl font-semibold text-gray-700 mt-2">Gas Car Owner Apology Request Form</h2>
          <p className="text-gray-600 italic">For the documentation of combustion-based transgressions</p>
        </div>

        <form onSubmit={handleSubmit} className="bg-white rounded-xl shadow-lg p-8 space-y-8">
          <section>
            <h3 className="text-xl font-semibold mb-4">EV Owner Information (Affected Party)</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name (as appears on Sustainable Living Certificate)
                </label>
                <input
                  type="text"
                  placeholder="Dr. Premium EV Owner, PhD in Sustainability"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
                  value={formData.evOwnerName}
                  onChange={e => setFormData({...formData, evOwnerName: e.target.value})}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Electric Vehicle Model (Minimum 3M THB value required)
                </label>
                <input
                  type="text"
                  placeholder="Enter your superior mode of transport"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
                  value={formData.evModel}
                  onChange={e => setFormData({...formData, evModel: e.target.value})}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Number of Social Media Posts About Being Electric
                </label>
                <input
                  type="number"
                  placeholder="Minimum 50 required for form processing"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
                  value={formData.socialPosts}
                  onChange={e => setFormData({...formData, socialPosts: e.target.value})}
                />
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-4">Offending Party Information</h3>
            <div className="space-y-4">
              {/* Transgression checkboxes */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Nature of Transgression (select all that apply)
                </label>
                <div className="space-y-2">
                  {[
                    'Parked in EV charging spot',
                    'Made eye contact with EV owner',
                    'Emitted audible engine noise',
                    'Expressed satisfaction with gas vehicle',
                    'Failed to show appropriate shame',
                    'Existed within visible range'
                  ].map(transgression => (
                    <label key={transgression} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={formData.transgressions.has(transgression)}
                        onChange={() => {
                          const newTransgressions = new Set(formData.transgressions);
                          if (newTransgressions.has(transgression)) {
                            newTransgressions.delete(transgression);
                          } else {
                            newTransgressions.add(transgression);
                          }
                          setFormData({...formData, transgressions: newTransgressions});
                        }}
                        className="rounded text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm text-gray-700">{transgression}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Desired Form of Apology
                </label>
                <select
                  value={formData.apologyType}
                  onChange={e => setFormData({...formData, apologyType: e.target.value})}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select an option</option>
                  <option>Public social media announcement of inferiority</option>
                  <option>Written apology in national newspaper</option>
                  <option>Interpretive dance of remorse</option>
                  <option>Immediate EV purchase with proof</option>
                  <option>Lifetime charging spot surrender</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Additional Comments on Emotional Distress Caused
                </label>
                <textarea
                  rows={4}
                  placeholder="Describe how this incident affected your sense of superiority..."
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
                  value={formData.comments}
                  onChange={e => setFormData({...formData, comments: e.target.value})}
                />
              </div>
            </div>
          </section>

          <button
            type="submit"
            className="w-full py-3 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2"
          >
            <Send className="w-5 h-5" />
            Submit Request for Apology
          </button>
        </form>

        <div className="mt-8 p-6 bg-gray-50 rounded-lg text-sm text-gray-600">
          <p className="font-medium mb-2">By submitting this form, you certify that:</p>
          <ul className="list-disc pl-5 mb-4 space-y-1">
            <li>You own an electric vehicle worth more than the offender's annual salary</li>
            <li>You have mentioned being electric at least 3 times today</li>
            <li>You have a valid Superiority Complex License</li>
            <li>You have never taken public transport</li>
          </ul>
          <p className="mb-2">Processing time: 3-5 business days or until offender shows appropriate level of shame</p>
          <p>RODFAIFA Group reserves the right to reject applications from owners of EVs under 3M THB value</p>
        </div>
      </div>
    </div>
  );
}