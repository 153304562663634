export const DEFAULT_LOCALE = 'en';
export const SUPPORTED_LOCALES = ['en', 'th'] as const;

export type Locale = 'en' | 'th';

interface Routes {
  home: string;      // Added home route
  compare: string;
  reviews: string;
  versus: string;
}

export interface SEOConfigType {
  routes: Routes;
  defaultTitle: string;
  defaultDescription: string;
}

export const SEO_CONFIG: Record<Locale, SEOConfigType> = {
  en: {
    defaultTitle: 'Rodfaifa - Thailand\'s Premier EV Comparison Platform',
    defaultDescription: 'Compare electric vehicles in Thailand. Find detailed specifications, prices, and real owner reviews of EVs from Tesla, BYD, MG, and more.',
    routes: {
      home: '/',
      compare: '/compare',
      reviews: '/reviews',
      versus: '/vs'
    }
  },
  th: {
    defaultTitle: 'รถไฟฟ้า - เว็บไซต์เปรียบเทียบรถยนต์ไฟฟ้าอันดับ 1 ในประเทศไทย',
    defaultDescription: 'เปรียบเทียบรถยนต์ไฟฟ้าในประเทศไทย ข้อมูลสเปค ราคา และรีวิวจากผู้ใช้จริง Tesla, BYD, MG และอื่นๆ',
    routes: {
      home: '/th',
      compare: '/th/เปรียบเทียบ',
      reviews: '/th/รีวิว',
      versus: '/th/vs'
    }
  }
};
